import { SIDE_SHOW, SIDE_HIDE, SIDE_ERROR } from '../types';

const initialState = {
    display : false,
    error : false,
    loading : false
};

export default function(state, action){
    if(typeof state === 'undefined'){
        return initialState;
    }

    switch(action.type){
        case SIDE_HIDE:
            return {
                ...state,
                display: false,
                loading : true
            };
        case SIDE_SHOW:
            return {
                ...state,
                display : true,
                error : false,
                loading : false 
            };
        case SIDE_ERROR:
            return {
                ...state,
                display : false,
                error : true,
                loading : false
            };
        default:
            return state;
    }
}