import { USERS_INIT, USERS_LIST, USERS_SELECTED, USERS_ERROR } from '../types';

const initialState = {
    list : {},
    selected: {},
    error : false,
    loading : false
};

export default function(state, action){
    if(typeof state === 'undefined'){
        return initialState;
    }

    switch(action.type){
        case USERS_INIT:
            return {
                ...state,
                loading : true
            };
        case USERS_LIST:
            return {
                ...state,
                list : action.payload,
                error : false,
                loading : false 
            };
        case USERS_SELECTED:
            return {
                ...state,
                selected : action.payload,
                error : false,
                loading : false
            };
        case USERS_ERROR:
            return {
                ...state,
                perfil : {},
                error : true,
                loading : false
            };
        default:
            return state;
    }
}