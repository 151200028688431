import { 
    USER_INIT, USER_SUCCESS, 
    USER_CREATED, USER_DELETED,USER_REJECTED, 
    USER_ERROR
} from '../types';

const initialState = {
    perfil : {},
    status: {},
    error : false,
    loading : false
};

export default function(state, action){
    if(typeof state === 'undefined'){
        return initialState;
    }

    switch(action.type){
        case USER_INIT:
            return {
                ...state,
                loading : true
            };
        case USER_SUCCESS:
            return {
                ...state,
                perfil : action.payload,
                error : false,
                loading : false 
            };
        case USER_CREATED:
        case USER_DELETED:
            return {
                ...state,
                status : action.payload,
                error : false,
                loading : false 
            };
        case USER_REJECTED:
            return {
                ...state,
                status : {},
                error : true,
                loading : false 
            };
        case USER_ERROR:
            return {
                ...state,
                perfil : {},
                error : true,
                loading : false
            };
        default:
            return state;
    }
}
